import { Component, ViewChild } from "@angular/core";
import { SwalComponent, SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import packageJson from '../../../../package.json';
import { DatabaseService } from "src/app/servicios/database/database.service";
import { CdkCopyToClipboard } from "@angular/cdk/clipboard";
import { UpdateService } from "src/app/servicios/update/update.service";


@Component({
  selector: "app-acerca-de",
  templateUrl: "./acerca-de.component.html",
  styleUrls: ["./acerca-de.component.scss"],
  providers: [
    CdkCopyToClipboard
  ]
})
export class AcercaDeComponent {
  @ViewChild("aboutSwal") aboutSwal: SwalComponent;
  @ViewChild("changesSwal") changesSwal: SwalComponent;

  fileUrl =
    "https://www.ccn-cert.cni.es/amparo/API/public/certificaciones/3086/download/2077";

  swalConfirmButtonAndIconColor = '#294ADB';
  swalWidth = '450px';
  
  version: string = packageJson.version;
  versionInfo = null


  constructor(
    public readonly swalTargets: SwalPortalTargets,
    private db: DatabaseService,
    private clipboard: CdkCopyToClipboard,
    private updateService: UpdateService,
  ) {
  }

  getCurrentVersionChanges() {
    this.getVersionChanges(this.version);
  }

  getVersionChanges(version) {
    this.db.getLatestRelease(version).subscribe(
      (release) => {
        this.versionInfo = release;
        this.changesSwal.fire();
      }
    )
  }

  clearUpdateMessage() {
    this.updateService.updateMessageShown();
  }

  copyReleaseNotes() {
    this.clipboard.text = this.versionInfo.body;
    this.clipboard.copy()
    return false;
  }

  checkUpdates() {
    this.updateService.checkForUpdate();
  }
}
