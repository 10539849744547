import { BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard} from './Guards/auth.guard'

// Components
import { LoginComponent } from './component/login/login.component';
import { HeaderComponent } from './component/header/header.component';
import { PerfilUsarioComponent} from './dialogs/perfil-usario/perfil-usario.component';
import { ModificarUsuarioComponent } from './dialogs/modificar-usuario/modificar-usuario.component';
import { ConfMunicipioComponent} from './dialogs/conf-municipio/conf-municipio.component';

// Library
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { MatSelectModule } from '@angular/material/select';
import { AuthinterceptorProvider } from './servicios/auth.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatBadgeModule} from '@angular/material/badge';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { ModificarMunicipioComponent } from './dialogs/modificar-municipio/modificar-municipio.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BnNgIdleService } from 'bn-ng-idle';
import { Select2Module } from 'ng-select2-component';
import { MarkdownModule } from 'ngx-markdown';

// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as charts from "fusioncharts/fusioncharts.charts";
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import * as TimeSeries from 'fusioncharts/fusioncharts.timeseries';
import * as widgets from 'fusioncharts/fusioncharts.widgets';
import * as PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import { FusionChartsModule } from 'src/app/utils/angular-fusioncharts/fusioncharts.module';
import { LoaderSpinnerModule } from './component/loader-spinner/loader-spinner.component';
import { LoaderService } from './component/loader-spinner/loader.service';
import { ModuleRedirectComponent } from './component/module-redirect/module-redirect.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IsAllowedDirective} from './utils/directives/isAllowed/is-allowed.directive'
//import {IpinterceptorProvider} from './servicios/ip.interceptor';
import { FsessionService } from './servicios/session/fsession.service';
import { SubirManualesComponent } from './dialogs/subir-manuales/subir-manuales.component';
import {AuthModule} from './servicios/msal-config/auth.module';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { LoginFailedComponent } from './component/login-failed/login-failed.component';
import { InicializadorAppsComponent } from './dialogs/inicializador-apps/inicializador-apps.component';
import {MatRippleModule} from '@angular/material/core';
import { AcercaDeComponent } from './dialogs/acerca-de/acerca-de.component';
import { UtilsModule } from './utils/utils.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { configuracion } from 'src/configuracion';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, configuracion.translations_URL, `.json?v=${ Date.now() }`);
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HeaderComponent,
        PerfilUsarioComponent,
        ModificarUsuarioComponent,
        ConfMunicipioComponent,
        ModificarMunicipioComponent,
        ModuleRedirectComponent,
        IsAllowedDirective,
        SubirManualesComponent,
        LoginFailedComponent,
        InicializadorAppsComponent,
        AcercaDeComponent,
    ],
    imports: [
        SweetAlert2Module.forRoot(),
        MatInputModule,
        MatBadgeModule,
        ScrollingModule,
        MatSlideToggleModule,
        MatListModule,
        MatMenuModule,
        MatToolbarModule,
        MatDialogModule,
        MatCardModule,
        FormsModule,
        BrowserModule,
        MatProgressSpinnerModule,
        HttpClientModule,
        AppRoutingModule,
        MatIconModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        MatSelectModule,
        MatSidenavModule,
        FlexLayoutModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatGridListModule,
        MatAutocompleteModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        LoaderSpinnerModule,
        Select2Module,
        MatCheckboxModule,
        AuthModule,
        MatRippleModule,
        NgxMatSelectSearchModule,
        MarkdownModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        FusionChartsModule.forRoot(FusionCharts, charts, FusionTheme, PowerCharts, TimeSeries,widgets),
        UtilsModule,
        TranslateModule.forRoot({
          defaultLanguage: 'es',
          loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient]
          }
        }),
    ],
    providers: [
        AuthinterceptorProvider,
        //IpinterceptorProvider,
        AuthGuard,
        BnNgIdleService,
        LoaderService,
        FsessionService,
        {provide: APP_BASE_HREF, useValue: '/'}
      ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  private svgIcons = [
    { name: 'pausePersonal', url: '../../../../assets/imagenes/icons/sel/UP-04.svg' },
    { name: 'pausePersonalSel', url: '../../../../assets/imagenes/icons/UP-15.svg' },
    { name: 'playPersonal', url: '../../../../assets/imagenes/icons/UP-02P.svg' },
    { name: 'playPersonalSel', url: '../../../../assets/imagenes/icons/UP-13.svg' },
    { name: 'stopPersonalSel', url: '../../../../assets/imagenes/icons/UP-14.svg' },
    { name: 'stopPersonal', url: '../../../../assets/imagenes/icons/sel/UP-03.svg' },
    { name: 'notifications', url: '../../../../assets/imagenes/icons/notifications_black_24dp.svg' },
    { name: 'flecha-lado', url: '../../../../assets/imagenes/icons/UP-07.svg' },
    { name: 'paso-cebra', url: '../../../../assets/imagenes/icons/crosswalk.svg' },
    { name: 'foto-rojo', url: '../../../../assets/imagenes/icons/semaforo.svg' },
    { name: 'infraccion', url: '../../../../assets/imagenes/icons/policia.svg' },
    { name: 'zbe', url: '../../../../assets/imagenes/icons/zbe.svg' },
    { name: 'usuario', url: '../../../../assets/imagenes/icons/UP-08.svg' },
    { name: 'menu', url: '../../../../assets/imagenes/icons/UP-09.svg' },
    { name: 'flechaDown', url: '../../../../assets/imagenes/icons/UP-10.svg' },
    { name: 'flechaDownBlanca', url: '../../../../assets/imagenes/icons/arrow-white-10.svg' },
    { name: 'addCam', url: '../../../../assets/imagenes/icons/video-camera-01.svg' },
    { name: 'conf1', url: '../../../../assets/imagenes/icons/video-camera-02.svg' },
    { name: 'conf2', url: '../../../../assets/imagenes/icons/video-camera-03.svg' },
    { name: 'sign-off', url: '../../../../assets/imagenes/icons/iconos2-13.svg' },
    { name: 'map', url: '../../../../assets/imagenes/icons/iconos2-07.svg' },
    { name: 'logoMovil', url: '/assets/imagenes/icons/iconos2-02.svg' },
    { name: 'cloud', url: '/assets/imagenes/icons/cloud.svg' },
    { name: 'sync', url: '/assets/imagenes/icons/UP-04.svg' },
    { name: 'check_circle', url: '/assets/imagenes/icons/check_circle.svg' },
    { name: 'warning', url: '/assets/imagenes/icons/iconos-10.svg' },
    { name: 'parking-icon', url: '/assets/imagenes/icons/parking-icon.svg' },
    { name: 'azure-logo', url: '/assets/imagenes/azurelogo.svg' },
    // Banderas idiomas
    { name: 'es', url: '/assets/imagenes/flags/es.svg' },
    { name: 'ca', url: '/assets/imagenes/flags/es-ct.svg' },
    { name: 'fr', url: '/assets/imagenes/flags/fr.svg' },
    { name: 'en', url: '/assets/imagenes/flags/en.svg' },
    { name: 'pv', url: '/assets/imagenes/flags/es-pv.svg' },
  ];

  constructor (private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer){
    this.svgIcons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
      );
    });    
  }
}
